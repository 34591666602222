<template>

	<div class="section_wrap pt-50 pb-80" ref="post_list_item_view">
		<div class="container">
			<div class="row">
				<!-- 카드 정보 -->
				<div class="cont_box cont_box_type2 mt-20">

					<div class="text-center">
						<img :src="$request.upload_url(currency_id)" :alt="currency_id" style="width: 40px"/>
					</div>
					<table class="nft_info_table">
						<colgroup>
							<col width="60px" />
							<col width="auto" />
						</colgroup>
						<thead>
						</thead>
						<tbody>
						<template
							v-if="items_history.length > 0"
						>
							<template
								v-for="(history, index) in items_history"
							>
								<tr
									:key="'history3_' + index"
								>
									<td><div class="mt-30">{{  history.tranmsn_state_name }}</div></td>
									<td class="tb_ing_gray"><div class="mt-30">{{  history.tranmsndt }}</div></td>
								</tr>
								<tr
									:key="'history4_' + index"
									class=""
								>
									<td>from</td>
									<td class="tb_ing_gray text-right">
										<v-icon small class="color-gray mr-1" @click="$bus.$emit('clipboard', history.withdrawal_wallet_address)">mdi-content-copy</v-icon>{{ history.withdrawal_wallet_address | tokenAddress(9, 9)}}
									</td>
								</tr>
								<tr
									:key="'history5_' + index"
									class=""
								>
									<td>to</td>
									<td class="tb_ing_gray text-right">
										<v-icon small class="color-gray mr-1" @click="$bus.$emit('clipboard', history.receive_wallet_address)">mdi-content-copy</v-icon>{{ history.receive_wallet_address | tokenAddress(9, 9)}}
									</td>
								</tr>
								<tr
									:key="'history2_' + index"
								>
									<td>
										거래ID
									</td>
									<td class="tb_ing_gray text-right">
										<a
											:href="explorer + history.transaction_id" target="txid" class="color-gray"
										>{{  history.transaction_id | tokenAddress(10, 10)}} </a>
									</td>
								</tr>
								<tr
									:key="'history_' + index"
									class="under-line"
								>
									<td><div class="mb-30">{{  history.tranmsn_div_name }}</div></td>
									<td class="tb_ing_gray text-right"><div class="mb-30">{{  history.tranmsn_coin_token_quantity | makeComma(8)}}</div></td>
								</tr>
							</template>
						</template>
						<tr
							v-else
						>
							<td colspan="3" style="text-align: center; padding: 50px">no data</td>
						</tr>
						</tbody>
					</table>
				</div>
				<!-- //카드 정보 -->
			</div>
		</div>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"
			:top="$refs.cartel_cover"

			@change="getSearchData"
		></ScrollPagination>
	</div>
</template>

<script>
import ScrollPagination from "@/components/ScrollPagination";
export default {
	name: 'mafia32'
	,
	components: {ScrollPagination},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.common.transfer_history
				,type: 'popup'
				,title: this.$language.common.transfer_history
			}
			, currency_id: this.$route.params.id
			, wallet: {

			}
			,items_history: [

			]
			, item_wallet: {}
			, item_search: {
				page_number: 1
				, list_cnt: 10
			}
			, max: false
			, is_add: false
		}
	}
	,computed: {
		is_sample: function(){
			if(process.env.VUE_APP_TYPE == 'sample'){
				return true
			}else{
				return false
			}
		}
		, explorer: function(){
			let t = 'https://baobab.scope.klaytn.com/tx/0x'
			let location = document.location.href
			if(location.indexOf(process.env.VUE_APP_DOMAIN_M) > -1){
				t = 'https://scope.klaytn.com/tx/0x'
			}

			return t
		}
	}
	,methods: {
		getWalletHistory: async function(){
			try{
				this.is_add = false
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_wallet_history
					,data: {
						member_number: this.user.member_number
						, coin_token_code: this.currency_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.list_cnt
					}
					,type: true
				})

				if(result.success){
					if(result.data.deptwidal_list.length > 0){
						this.items_history = this.items_history.concat(result.data.deptwidal_list)
					}else{
						this.max = true
					}
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
				this.is_add = true
			}
		}
		, getSearchData: function(page){
			if(page){
				this.item_search.page_number = page
			}

			this.getWalletHistory()
		}
	}

	,async created() {
		this.$emit('onLoad', this.program)
		await this.getWalletHistory()
	}

}
</script>